<template>
  <b-modal title="Add Organization" size="lg" v-model="active">
    <b-row class="form-group">
      <b-col md="6">
        <label>Name</label>
        <b-form-input type="text" placeholder="" v-model.trim="organization.name" :state="state($v.organization.name)" @input="touch($v.organization.name)"></b-form-input>
        <b-form-invalid-feedback>This is a required field</b-form-invalid-feedback>
      </b-col>
      <b-col md="6">
        <label>Parent Organization</label>
        <b-form-select :options="organizations" v-model="organization.parentOrganizationId" :state="state($v.organization.parentOrganizationId)" @input="touch($v.organization.parentOrganizationId)"></b-form-select>
      </b-col>
    </b-row>
    <div slot="modal-footer">
      <b-btn size="sm" class="float-right" :disabled="$v.$invalid" variant="success" @click="save()">
        Add Organization
      </b-btn>
    </div>
  </b-modal>
</template>

<script>
let roles = []
import { required, minLength, sameAs } from "vuelidate/lib/validators"

export default {
  name: 'OrganizationModal',
  data () {
    return {
      organizations: [
        { value: null, text: 'Select Organization...', disabled: true }
      ],
      organization: {
        name: null,
        parentOrganizationId: null
      },
      active: false
    }
  },
  methods: {
    state (vField) {
      if (typeof(vField) != 'undefined') {
        return vField.$dirty ? !vField.$invalid : null
      }
    },
    touch (vField) {
      if (typeof(vField) != 'undefined') {
        vField.$touch()
      }
    },
    show (organization) {
      this.active = true
      if (organization) {
        this.organization = { ...organization }
      } else {
        this.organization = {
          name: null,
          parentOrganizationId: null
        }
      }
    },
    save () {
      let organization = this.organization
      this.$emit('save', { ...organization })
      this.active = false
    }
  },
  created () {
    this.$bus.$on('modal:editOrganization', (organization) => {
      this.show(organization);
      this.$nextTick(() => { this.$v.$reset() })
    });

    this.$api.accounts.organizations.get()
      .then((response) => {
        response.data.forEach((organization) => {
          this.organizations.push({ value: organization.id, text: organization.name })
        })
      })
      .catch((err) => {
        console.log(err)
      })
  },
  validations: {
    organization: {
      name: {
        required
      }
    }
  }
}
</script>
