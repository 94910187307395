<template>
  <b-row>
    <b-col cols="12" sm="12">
      <transition name="slide">
      <b-card>
        <b-table :hover="hover" :striped="striped" :bordered="bordered" :small="small" :fixed="fixed" responsive="sm" :items="organizations" :fields="fields" :current-page="currentPage" :per-page="perPage" @row-clicked="rowClicked">
        </b-table>
        <nav>
          <b-row>
            <b-col sm="8">
              <b-pagination size="sm" :total-rows="getRowCount(organizations)" :per-page="perPage" v-model="currentPage" prev-text="Prev" next-text="Next" hide-goto-end-buttons/>
            </b-col>
            <b-col sm="4" class="text-right">
              <b-button size="sm" variant="success" @click="addOrganization">Add New Organization</b-button>
            </b-col>
          </b-row>
        </nav>
      </b-card>
      </transition>
      <organization-modal @save="create"></organization-modal>
    </b-col>
  </b-row>
</template>

<script>
import OrganizationModal from '@/components/modals/OrganizationModal'

export default {
  name: 'Organizations',
  components: {
    OrganizationModal
  },
  props: {
    caption: {
      type: String,
      default: 'Organizations'
    },
    hover: {
      type: Boolean,
      default: true
    },
    striped: {
      type: Boolean,
      default: true
    },
    bordered: {
      type: Boolean,
      default: false
    },
    small: {
      type: Boolean,
      default: true
    },
    fixed: {
      type: Boolean,
      default: false
    }
  },
  data: () => {
    return {
      organizations: [],
      fields: [
        { key: 'name', label: 'Name' },
        { key: 'notificationEmailAddress.address', label: 'Notification Email' },
        { key: 'billingEmailAddress.address', label: 'Billing Email' },
      ],
      currentPage: 1,
      perPage: 20,
      totalRows: 0
    }
  },
  computed: {
  },
  methods: {
    fetchOrganizations () {
      this.$api.accounts.organizations.get()
      .then((response) => {
        this.organizations = response.data
      })
      .catch((err) => {
        this.$snotify.error('Error Fetching Organizations')
      })
    },
    getRowCount (items) {
      return items.length
    },
    userLink (id) {
      return `${id.toString()}`
    },
    rowClicked (item) {
      this.$router.push({ name: 'Organization', params: { id: item.id }})
    },
    addOrganization () {
      this.$bus.$emit('modal:editOrganization')
    },
    create (organization) {
      this.$api.accounts.organizations.create(organization)
        .then((response) => {
          this.$snotify.success('Organization Created Successfully')
          this.fetchOrganizations()
        })
        .catch((err) => {
          this.$snotify.error('Error Fetching Organizations')
        })
    }
  },
  created () {
    this.fetchOrganizations()
  }
}
</script>

<style scoped>
.card-body >>> table > tbody > tr > td {
  cursor: pointer;
}
</style>
